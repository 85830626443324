import { Carousel, Divider, Layout } from 'antd';
import Image from 'next/image';
import styles from './LoginLayout.module.scss';
import cls from 'classnames';
import { formatAssetUrl, myNextImgLoader } from '@/utils/util';
import { BackIcon } from '@/constants';
import LangSelect from '@/components/intl/LangSelect';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useRouter } from 'next/router';
const { Content } = Layout;

interface BackProps {
  callback?(): void;
}

export const BackButton: React.FC<BackProps> = ({ callback }) => {
  return (
    <div className={cls(styles.back, 'cursor-pointer select-none')} onClick={callback}>
      <span style={{ marginRight: 4, fontWeight: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <BackIcon />
      </span>
      <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <FormattedMessage id="common.back" defaultMessage={'Back'} />
      </span>
    </div>
  );
};

interface HeaderTypographyProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  titleStyle?: React.CSSProperties;
}

export const HeaderTypography: React.FC<HeaderTypographyProps> = ({ title, subtitle, titleStyle }) => {
  return (
    <div className={styles.typography}>
      <h2 className={styles.typography__title} style={titleStyle}>
        {title}
      </h2>
      {Boolean(subtitle) && <div className={styles.typography__subtitle}>{subtitle}</div>}
    </div>
  );
};

const LoginLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { locale } = useIntl();

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <div className={styles.langSelect}>
          <a href={`https://www.youdaoads.com/${locale}`}>
            <Image src={formatAssetUrl('/youdaoAdslogo.png')} width={164.461} height={32} alt="logo" loader={myNextImgLoader} />
          </a>
          <div>
            <LangSelect />
          </div>
        </div>

        <div
          className={styles.contentLeft}
          style={{
            background: ` url(${formatAssetUrl('/layout/login_bg.png')})`,
            backgroundSize: '100% 100%',
          }}
        >
          <div className={styles.center}>
            <div className={styles.addSub}>
              <div className={styles.addTraffic}>
                <FormattedMessage id="login.addTrafficUpper" defaultMessage={'Adding Social Media'} />
              </div>
              <div className={styles.addTrafficSub}>
                <FormattedMessage
                  id="login.addTrafficSub"
                  defaultMessage={'<Block>MAKING</Block><Block>MONEY</Block>'}
                  values={{
                    Block: (chunks) => <div className="block">{chunks}</div>,
                  }}
                />
              </div>
            </div>

            <Image src={formatAssetUrl(`/layout/bgimg.png`)} width={486} height={440} className={styles.bgImg} alt="img" loader={myNextImgLoader} />
          </div>

          <div className={styles.policy}>
            <a target="_blank" rel="noopener noreferrer" href={`https://www.youdaoads.com/user-terms?hl=${locale}`}>
              <FormattedMessage id="common.userTerms" defaultMessage="User Terms" />
            </a>
            <Divider type="vertical" />
            <a target="_blank" rel="noopener noreferrer" href={`https://www.youdaoads.com/a/private-policy?hl=${locale}`}>
              <FormattedMessage id="common.privatePolicy" defaultMessage="Privacy Policy" />
            </a>
          </div>
        </div>

        <div style={{ flex: 'auto' }} className={styles.body}>
          <div className={styles.childrenContainer}>{children}</div>
          <div className={styles.footer}>
            <Divider className={styles.footerDivider} />
            <a className={styles.goHome} href={`https://www.youdaoads.com/${locale}`}>
              <Image src={formatAssetUrl('/favicon.png')} width={24} height={24} alt="logo" />
              <div className={styles.goHomeText}>
                <FormattedMessage id="common.goToHomepage" defaultMessage="Enter the official website" />
              </div>
            </a>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default LoginLayout;
